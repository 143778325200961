<template>
    <Layout>
      <template v-if="this.$route.name == 'add-campaign-content'">
        <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
        <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body" id="medinext">
              <b-form enctype="multipart/form-data" ref="promotion">
                <div class="row">
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_title">Campaign Content Title <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_title"
                        placeholder="Enter Campaign Title"
                        v-model="form.title"
                        :class="{
                          'is-invalid': submitted && $v.form.title.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                            Campaign Title is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_description">Campaign Content Description <span style="color: red">*</span></label>
                        <b-form-textarea
                        type="text"
                        id="campaign_description"
                        placeholder="Enter Campaign Content Description"
                        v-model="form.description"
                        :class="{
                          'is-invalid': submitted && $v.form.description.$invalid,
                        }"
                        ></b-form-textarea>
                        <div v-if="submitted && !$v.form.description.required" class="invalid-feedback">
                            Campaign Content Description is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="campaign_title">Campaign Content Link <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_link"
                        placeholder="Enter Campaign content Link"
                        v-model="form.link"
                        :class="{
                          'is-invalid': submitted && $v.form.link.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.link.required" class="invalid-feedback">
                            Campaign Content Link is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12"
                        label="Select an option"
                        label-for="static-select"
                    >
                        <b-form-select id="static-select" v-model="form.type">
                        <option value="" disabled>Select an type for Campaign Content Type</option>
                        <option value="journal">Journal</option>
                        <option value="ecg">ECG</option>
                        </b-form-select>
                    </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="logo">Campaign Content Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="image"
                      @change="readFile($event, 'image')"
                      ref="image"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-campaign-content' && edit.img_url"
                    >
                      <img
                        :src="edit.img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                    <template v-if="img_url">
                      <img
                        :src="img_url"
                        width="128px"
                        height="128px"
                        ref="img_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                </div>
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                >
                  <span v-if="this.$route.name == 'add-campaign-content'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../../mixins/request";
  import campConentMixin from "../../../../mixins/ModuleJs/campaign-content";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    data() {
      return {
        submitted: false,
        title1: "Add Campaign Content",
        title2: "Edit Campaign Content",
        items: [
          {
            text: "List",
            href: "/field-rep-app/campaign-contents",
          },
          {
            text: "Data",
          },
        ],
      };
    },
    mixins: [MixinRequest, campConentMixin],
    components: {
      Layout,
      PageHeader
    },
    validations: {
      form: {
        title: { required },
        description: { required },
        link: { required },
        // image: { required }
      },
    },
  };
  </script>
  