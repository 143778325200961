var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[(this.$route.name == 'add-campaign-content')?[_c('PageHeader',{attrs:{"title":_vm.title1,"items":_vm.items}})]:[_c('PageHeader',{attrs:{"title":_vm.title2,"items":_vm.items}})],_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body",attrs:{"id":"medinext"}},[_c('b-form',{ref:"promotion",attrs:{"enctype":"multipart/form-data"}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('label',{attrs:{"for":"campaign_title"}},[_vm._v("Campaign Content Title "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.title.$invalid,
                    },attrs:{"type":"text","id":"campaign_title","placeholder":"Enter Campaign Title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),(_vm.submitted && !_vm.$v.form.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Campaign Title is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('label',{attrs:{"for":"campaign_description"}},[_vm._v("Campaign Content Description "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-textarea',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.description.$invalid,
                    },attrs:{"type":"text","id":"campaign_description","placeholder":"Enter Campaign Content Description"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(_vm.submitted && !_vm.$v.form.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Campaign Content Description is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('label',{attrs:{"for":"campaign_title"}},[_vm._v("Campaign Content Link "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                      'is-invalid': _vm.submitted && _vm.$v.form.link.$invalid,
                    },attrs:{"type":"text","id":"campaign_link","placeholder":"Enter Campaign content Link"},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}}),(_vm.submitted && !_vm.$v.form.link.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Campaign Content Link is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-lg-6 col-md-6 col-sm-12",attrs:{"label":"Select an option","label-for":"static-select"}},[_c('b-form-select',{attrs:{"id":"static-select"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Select an type for Campaign Content Type")]),_c('option',{attrs:{"value":"journal"}},[_vm._v("Journal")]),_c('option',{attrs:{"value":"ecg"}},[_vm._v("ECG")])])],1),_c('b-form-group',{staticClass:"col-lg-6 col-md-6 col-sm-12"},[_c('label',{attrs:{"for":"logo"}},[_vm._v("Campaign Content Image")]),_c('b-form-file',{ref:"image",attrs:{"accept":"image/*","placeholder":"Choose a file or drop it here...","id":"image"},on:{"change":function($event){return _vm.readFile($event, 'image')}}}),(_vm.$route.name == 'edit-campaign-content' && _vm.edit.img_url)?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.img_url,"width":"128px","height":"128px"}})]:_vm._e(),(_vm.img_url)?[_c('img',{ref:"img_url",staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.img_url,"width":"128px","height":"128px"}})]:_vm._e()],2)],1),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[(this.$route.name == 'add-campaign-content')?_c('span',[_vm._v("Save Data")]):_c('span',[_vm._v("Update Data")])])],1)],1)])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }